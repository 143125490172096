import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import { styled } from '@mui/material/styles';
import Diplomes from "./Diplomes";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


function Certificats() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
      <h1 className="project-heading">
  Mes <strong className="purple">Certifications </strong>
</h1>

<p style={{ color: "white" }}>
  Voici mes Certifications.
</p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={bitsOfCode}
              isBlog={false}
              title="sql"
              description=""
              ghLink=""
              demoLink=""
            />
            <BorderLinearProgress variant="determinate" value={100} />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={bitsOfCode}
              isBlog={false}
              title="Azure"
              description=""
              ghLink=""
              demoLink=""
            />
            <BorderLinearProgress variant="determinate" value={100} />
          </Col>
          <Diplomes/>
        </Row>
        
      </Container>
      
    </Container>
  );
}

export default Certificats;

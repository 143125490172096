import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import HtAuto from "../../Assets/HtAuto.png";
import Cards from "../Projects/notProject";
import Accueil from "../../Assets/realisations/htauto/accueil.png";
import PhpMvc from "../../Assets/realisations/htauto/phpmvc.jpg";
import Connexion from "../../Assets/realisations/htauto/connexionAdmin.png";
import Admin from "../../Assets/realisations/htauto/admin.png";
import { FaPhp } from "react-icons/fa";
import { BsFiletypeCss } from "react-icons/bs";
import { MdHtml } from "react-icons/md";
import { TbBrandCSharp } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { SiMysql } from "react-icons/si";

function Htauto() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" >
          <strong className="purple">Htauto </strong>
        </h1>
        <Row>
         
        
          <Col md={6} className="project-card">
            <Cards imgPath={Accueil} isBlog={true} />
          </Col>

          <Col md={6} className="project-card">
          <h3 className="project-heading">
          Context 
        </h3>
        <p style={{ color: "white" }}>
          HT Automobiles est un spécialiste de la vente de véhicules d'occasion
          à prix discount aux Abymes à Dothémare.
          
        </p>
        </Col>
    
    </Row>


        <h2 className="project-heading">
          <strong>Technologies Utilisées </strong>
        </h2>
        <Row
          style={{
            justifyContent: "center",
            paddingBottom: "50px",
            color: "white",
          }}
        >
          <Col xs={4} md={2} className="tech-icons">
            <FaPhp />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <MdHtml />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <BsFiletypeCss />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <SiJavascript />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <SiMysql />
          </Col>
        </Row>

        <Row>
         
        
         <Col md={6} className="project-card">
           <Cards imgPath={PhpMvc} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Modele MVC 
       </h3>
       <p style={{ color: "white" }}>
         HT Automobiles est un spécialiste de la vente de véhicules d'occasion
         à prix discount aux Abymes à Dothémare.
         
       </p>
       </Col>
       </Row>


       <h2 className="project-heading">
          <strong>Fonctionnalités et Caractéristiques </strong>
        </h2>
        <Row>
         
        
         <Col md={6} className="project-card">
           <Cards imgPath={Connexion} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Formulaire de connexion  
       </h3>
       <p style={{ color: "white" }}>
        les utilisateurs et les admins ont des Fonctionnalités differents
         
       </p>
       </Col>
   
   </Row>
   <Row>
         
   <Col md={6} className="project-card">
         <h3 className="project-heading">
         Page Gestionnaire 
       </h3>
       <p style={{ color: "white" }}>
         pour ajouter modifier et supprimer 
         
       </p>
       </Col>
         <Col md={6} className="project-card">
           <Cards imgPath={Admin} isBlog={true} />
         </Col>

         
   
   </Row>


   

        <Row style={{ justifyContent: "center", paddingBottom: "0px" }}>
          <Col md={6} className="project-card">
            <Cards imgPath={Accueil} isBlog={true} />
          </Col>
          <Col md={4} className="project-card">
            <Cards
              imgPath={HtAuto}
              isBlog={true}
              title=""
              description=""
              ghLink="https://github.com/McleanServices/htauto"
              demoLink="/htauto"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Htauto;

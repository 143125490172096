import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub} from "react-icons/bs";
import { FaDownload } from "react-icons/fa";




function Cards(props) {
  const openDemo = (url) => {
    const windowFeatures = "width=800,height=600,resizable=yes,scrollbars=yes,status=yes";
    window.open(url, "_blank", windowFeatures);
  };

  return (
    <Card className="project-card-view">
      {/* variant="top" */}
      <Card.Img  src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {/* <Button variant="primary" href={props.ghLink} target="_blank">
          <BsGithub /> &nbsp;
          {props.isBlog ? "Blog" : "GitHub"}
        </Button> */}
        {"\n"}
        {"\n"}

        {/* If the component contains a Demo link and if it's not a Blog, render this button */}
        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            onClick={() => openDemo(props.demoLink)}
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Démo"}
          </Button>
        )}
        {props.downloadLink && (
          <Button
            variant="secondary"
            href={props.downloadLink}
            download
            style={{ marginLeft: "10px" }}
          >
            <FaDownload /> 
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default Cards;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import HtAuto from "../../Assets/HtAuto.png";
import LavageAuto from "../../Assets/lavageauto.png";
import trokannou from "../../Assets/trokannou.png";
import gestionDeStock from "../../Assets/gestiondestock.png";
import { SiCsharp } from "react-icons/si";
import pdf from "../../Assets/../Assets/stage.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Cards from "./notProject";
import TechstackP from "./TechstackP";
import CVWEB from "../../Assets/realisations/cvweb.png";
import Linked from "../../Assets/realisations/linked.png";
import Pro from "../../Assets/realisations/pro.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Accompagnement </strong>Personnel
        </h1>

        <p style={{ color: "white" }}>
          
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={Pro}
              isBlog={false}
              title="CV Web"
              description="Creer une CV en ligne"
              ghLink="https://github.com/McleanServices/htauto"
              demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="(AutoFlex) Ht Auto"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              ghLink="https://github.com/McleanServices/htauto"
              demoLink="/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <p style={{ color: "white" }}>
          
        </p>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="Portefeuille de compétences"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              ghLink="https://github.com/McleanServices/htauto"
              demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col> */}
          {/* <Col md={3} className="project-card">
            <Cards
              imgPath={HtAuto}
              isBlog={false}
              title="Métiers"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              // ghLink="https://github.com/McleanServices/htauto"
              demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col> */}
          <Col md={3} className="project-card">
            <Cards
              imgPath={Linked}
              isBlog={true}
              title="Profil Linkedin"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              // ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <Cards
              imgPath={Pro}
              isBlog={true}
              title="Serveurs FTP"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              // ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <Cards
              imgPath={Pro}
              isBlog={true}
              title="Automatisation"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              // ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={LavageAuto}
              isBlog={false}
              title="Lavage Auto"
              description="Lavage Auto"
              ghLink="https://github.com/McleanServices/lavageauto"
              demoLink="https://techwithtyrece.com/lavageauto"
              downloadLink="https://techwithtyrece.com/lavageauto/lavageauto.zip"
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gestionDeStock}
              isBlog={false}
              title="Gestion de stock"
              description=""
              ghLink=""
              demoLink=""
            />
          </Col> */}

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={trokannou}
              isBlog={false}
              title="Troknnou"
              description="une application flutter pour faire des service et des pretes "
              ghLink="https://github.com/McleanServices/TROK-AN-NOU-FLUTTER"
              demoLink="https://techwithtyrece.com/trokannou/"
              downloadLink="https://techwithtyrece.com/lavageauto/lavageauto.zip"
            />
          </Col> */}
        </Row>
        <h1 className="project-heading">
          <strong className="purple">Travaux </strong>Pratique & Dirigé
        </h1>

        <Row>
          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="lycee guadeloupe"
              description="Mysql"
              ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>

          <Col md={6} className="project-card">
            <ProjectCard
              imgPath={Pro}
              isBlog={false}
              title="Bibliotheque"
              description="Application c#"
              ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>0000000000000000
        </Row>

        <h1 className="project-heading">
          <strong className="purple">Stage</strong>
        </h1>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Attestation De Stage
          </Button>
        </Row>
        <Row>
          <Col md={3} className="project-card">
            <Cards
              imgPath={Pro}
              isBlog={false}
              title="Azure"
              description="Démontrer une connaissance de base des concepts de base des données liées aux services de données Microsoft Azure."
              demoLink="https://learn.microsoft.com/en-us/credentials/certifications/azure-data-fundamentals/?practice-assessment-type=certification#two-ways-to-prepare"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <Cards
              imgPath={Pro}
              isBlog={false}
              title="Azure SQL"
              description="Expérience de l’utilisation, de la maintenance et du développement de SQL Server
              Expérience avec Azure, comme le déploiement et la gestion de ressources"
              demoLink="https://learn.microsoft.com/en-us/training/paths/azure-sql-fundamentals/"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <Cards
              imgPath={Pro}
              isBlog={false}
              title="Power Bi"
              description="Apprenez à vous connecter aux données et à les visualiser, en développant des compétences qui contribuent à créer une culture des données."
              demoLink="https://learn.microsoft.com/en-us/training/paths/azure-sql-fundamentals/"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
        </Row>

        <h1 className="project-heading">
          <strong className="purple">Compétences </strong>Obtenu
        </h1>
          <TechstackP/>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Container, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";






function Diplomes() {
  return (
    <Container>
      <h1 className="project-heading">
  Mes <strong className="purple">Diplomes</strong>
</h1>

<p style={{ color: "white" }}>
  Voici mes Diplomes.
</p>

       
          
          
          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={bitsOfCode}
              isBlog={false}
              title="Bac General "
              description=""
              ghLink=""
              demoLink=""
            />
            
          </Col>
        
    </Container>
  );
}

export default Diplomes;

import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Certificats from "./components/Certifications/Certificats";
import ContactForm from "./components/contact";
import ProjectAcademie from "./components/Projects/ProjectAcademie";
import SyntheseNew from "./components/Resume/sythese";
import Htauto from "./components/realisations/htauto";
import LavageAuto from "./components/realisations/lavageAuto";
import GestionStock from "./components/realisations/gestiondeStock";
import Veille from "./components/realisations/veille";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);
  

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/portfolio" element={<Home />} />
          <Route path="/veille" element={<Veille />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/htauto" element={<Htauto />} />
          <Route path="/lavageauto" element={<LavageAuto />} />
          <Route path="/gestionstock" element={<GestionStock />} />
          <Route path="/project2" element={<ProjectAcademie />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/synthese" element={<SyntheseNew />} />
          <Route path="/certifications" element={<Certificats/>}/>
          <Route path="*" element={<Navigate to="/portfolio"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

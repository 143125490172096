import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import HtAuto from "../../Assets/HtAuto.png";
import Cards from "../Projects/notProject";
import Accueil from "../../Assets/realisations/lavageauto/accueil.png";
import Devis from "../../Assets/realisations/lavageauto/devis.png";
import Devis3 from "../../Assets/realisations/lavageauto/devis3.png";
import Produits from "../../Assets/realisations/lavageauto/produits.png";
import Connexion from "../../Assets/realisations/lavageauto/connexion.png";
import Admin from "../../Assets/realisations/htauto/admin.png";
import { FaPhp } from "react-icons/fa";
import { BsFiletypeCss } from "react-icons/bs";
import { MdHtml } from "react-icons/md";
import { TbBrandCSharp } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { SiMysql } from "react-icons/si";

function LavageAuto() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" >
          <strong className="purple">Lavage Auto </strong>
        </h1>
        <Row>
         
        
          <Col md={6} className="project-card">
            <Cards imgPath={Accueil} isBlog={true} />
          </Col>

          <Col md={6} className="project-card">
          <h3 className="project-heading">
          Context 
        </h3>
        <p style={{ color: "white" }}>
        Une entreprise de lavage de voiture vous contacte car plusieurs de ses clients lui
demande son site internet, mais ce dernier n’en a pas. Vous devez donc créer un
site internet qui montre les produits proposés à la vente ainsi que les prestations
que effectuées par l’entreprise mais surtout il voudrait que le site dispose de la
fonctionnalité de génération de devis.
          
        </p>
        </Col>
    
    </Row>


        <h2 className="project-heading">
          <strong>Technologies Utilisées </strong>
        </h2>
        <Row
          style={{
            justifyContent: "center",
            paddingBottom: "50px",
            color: "white",
          }}
        >
          <Col xs={4} md={2} className="tech-icons">
            <FaPhp />
          </Col>
          <Col xs={4} md={2} className="tech-icons">
            <MdHtml />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <BsFiletypeCss />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <SiJavascript />
          </Col>

          <Col xs={4} md={2} className="tech-icons">
            <SiMysql />
          </Col>
        </Row>

       
       <h2 className="project-heading">
          <strong>Fonctionnalités et Caractéristiques </strong>
        </h2>
        <Row>
         
        
         <Col md={6} className="project-card">
           <Cards imgPath={Connexion} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Formulaire de connexion  
       </h3>
       <p style={{ color: "white" }}>
        les utilisateurs et les admins ont des Fonctionnalités differents
         
       </p>
       </Col>
   
   </Row>
   <Row>
         
        
        

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Page Produits
       </h3>
       <p style={{ color: "white" }}>
        les utilisateurs et les admins ont des Fonctionnalités differents
         
       </p>
       </Col>

       <Col md={6} className="project-card">
           <Cards imgPath={Produits} isBlog={true} />
         </Col>
   
   </Row>
   <Row>
         
        
         <Col md={6} className="project-card">
           <Cards imgPath={Connexion} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Page Prestations 
       </h3>
       <p style={{ color: "white" }}>
        les utilisateurs et les admins ont des Fonctionnalités differents
         
       </p>
       </Col>
   
   </Row>
   <Row>
         
        
       

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Formulaire de Devis
       </h3>
       <p style={{ color: "white" }}>
         HT Automobiles est un spécialiste de la vente de véhicules d'occasion
         à prix discount aux Abymes à Dothémare.
         
       </p>
       </Col>
       <Col md={6} className="project-card">
           <Cards imgPath={Devis} isBlog={true} />
         </Col>
       </Row>
       <Row>
         
        
       <Col md={6} className="project-card">
           <Cards imgPath={Devis3} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         Simulation Devis
       </h3>
       <p style={{ color: "white" }}>
         possibilite de telacharger ou imprimer
         
       </p>
       </Col>
       
       </Row>



   

        <Row style={{ justifyContent: "center", paddingBottom: "0px" }}>
          <Col md={6} className="project-card">
            <Cards imgPath={Accueil} isBlog={true} />
          </Col>
          <Col md={4} className="project-card">
            <Cards
              imgPath={HtAuto}
              isBlog={true}
              title=""
              description=""
              ghLink="https://github.com/McleanServices/htauto"
              demoLink="/htauto"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default LavageAuto;

import React from "react";
import { Col, Row } from "react-bootstrap";

import { FaPhp } from "react-icons/fa";
import { BsFiletypeCss } from "react-icons/bs";
import { MdHtml } from "react-icons/md";
import { TbBrandCSharp } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import {
  DiPython,
} from "react-icons/di";


function TechstackP() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", color: "white" }}>
      

      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <SiMysql />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <MdHtml />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <BsFiletypeCss />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <SiJavascript />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <TbBrandCSharp />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
      <FaPhp />
      </Col>

      


      
    </Row>
  );
}

export default TechstackP;

import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown"; // Import NavDropdown
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineContacts,
} from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [Personnels] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Accueil
              </Nav.Link>
            </Nav.Item>

            {/* Updated Nav.Item to a NavDropdown for "A propos de moi" */}
            <NavDropdown title={<span><AiOutlineUser style={{ marginBottom: "2px" }} />À propos</span>} id="about-dropdown">
              <NavDropdown.Item as={Link} to="/about" onClick={() => updateExpanded(false)}>Biographie</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/certifications" onClick={() => updateExpanded(false)}>Diplômes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/resume" onClick={() => updateExpanded(false)}>CV</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={<span><AiOutlineFundProjectionScreen style={{ marginBottom: "2px" }} />Mes réalisations</span>} id="about-dropdown">
              <NavDropdown.Item as={Link} to="/project" onClick={() => updateExpanded(false)}>Première Année</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/project2" onClick={() => updateExpanded(false)}>Deuxième Année</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/synthese" onClick={() => updateExpanded(false)}>Tableau de Synthèse</NavDropdown.Item>
            </NavDropdown>

            
            
           
            <Nav.Item>
              <Nav.Link as={Link} to="/veille" onClick={() => updateExpanded(false)}>
                <AiOutlineContacts style={{ marginBottom: "2px" }} />  Veille technologique
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                href="https://techwithtyrece.com/blog/"
                target="_blank"
                rel="noreferrer"
              >
                <ImBlog style={{ marginBottom: "2px" }} /> Blogs
              </Nav.Link>
            </Nav.Item> */}



            

            <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/McleanServices/TROK-AN-NOU-FLUTTER"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import HtAuto from "../../Assets/HtAuto.png";
import LavageAuto from "../../Assets/realisations/lavageauto/accueil.png";
import trokannou from "../../Assets/trokannou.png";
import gestionDeStock from "../../Assets/gestiondestock.png";
import { SiCsharp } from "react-icons/si";
import pdf from "../../Assets/../Assets/stage2.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Cards from "./notProject";
import TechstackD from "./TechtstackD";
import GLPI from "../../Assets/realisations/glpi.png";

function ProjectAcademie() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Accompagnement </strong>Personnel
        </h1>

        <p style={{ color: "white" }}>
          Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
         
         
          
          
          
          
          {/* <Col md={3} className="project-card">
            <Cards
              imgPath={HtAuto}
              isBlog={false}
              title="Automatisation"
              description="Une application web utilisant le modèle MVC en PHP, intégrant des méthodes CRUD."
              // ghLink="https://github.com/McleanServices/htauto"
              demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col> */}

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={LavageAuto}
              isBlog={false}
              title="Lavage Auto"
              description="Un
              site internet qui montre les produits proposés à la vente ainsi que les prestations
              que effectuées par l’entreprise mais surtout il voudrait que le site dispose de la
              fonctionnalité de génération de devis."
              ghLink="https://github.com/McleanServices/lavageauto"
              demoLink="/lavageauto"
              downloadLink="https://techwithtyrece.com/lavageauto/lavageauto.zip"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gestionDeStock}
              isBlog={false}
              title="Gestion de stock"
              description="Une application de gestion de stock de produits, accompagnée d'une base de données, en utilisant C# et Windows Forms"
              ghLink="https://github.com/McleanServices/TROK-AN-NOU-FLUTTER"
              // demoLink="/gestionstock"
              downloadLink="https://techwithtyrece.com/lavageauto/lavageauto.zip"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={trokannou}
              isBlog={false}
              title="Trok An Nou"
              description="Une application Flutter pour offrir des services et des prêts. "
              ghLink="https://github.com/McleanServices/TROK-AN-NOU-FLUTTER"
              demoLink="https://techwithtyrece.com/trokannou/"
              downloadLink="https://techwithtyrece.com/lavageauto/lavageauto.zip"
            />
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">Travaux </strong>Pratique & Dirigé
        </h1>

        <Row>
          <Col md={3} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="Gestion auto-école"
              description="Entity Framework"
              ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="Symfony"
              description="PHP"
              ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
          <Col md={3} className="project-card">
            <ProjectCard
              imgPath={GLPI}
              isBlog={false}
              title="OCS & GLPI"
              description="via Linux Debian"
              ghLink="https://github.com/McleanServices/htauto"
              // demoLink="https://techwithtyrece.com/htauto"
              // downloadLink="https://techwithtyrece.com/htauto/htauto.zip"
            />
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">Stage</strong>
        </h1>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Attestation De Stage
          </Button>
        </Row>
        <Row>
         
          
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={HtAuto}
              isBlog={false}
              title="Systeme de base de donnes"
              description="tax automobile"
              // demoLink="https://learn.microsoft.com/en-us/training/paths/azure-sql-fundamentals/"
              downloadLink="../../Assets/../Assets/RAPPORTDESTAGE.pdf"
            />
          </Col>
        </Row>

        <h1 className="project-heading">
          <strong className="purple">Compétences </strong>Obtenu
        </h1>
          <TechstackD/>
      </Container>
    </Container>
  );
}

export default ProjectAcademie;

import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Bonjour,  je m'appelle <span className="purple">Mclean Tyrece </span>
            
            <br/>
            Je suis actuellement étudiant au lycée Baimbridge en Guadeloupe, poursuivant un diplôme en BTS SIO (service informatique aux organisations).
            <br />
           </p>
            {/* <br />
            En dehors de la programmation, voici quelques autres activités que j'aime faire !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Jouer à des jeux
            </li>
            <li className="about-activity">
              <ImPointRight /> Écrire des blogs sur la technologie
            </li>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
          </ul> */}

          <p style={{ color: "rgb(155 126 172)" }}>
            "Efforcez-vous de construire des choses qui font la différence !"{" "}
          </p>
          <footer className="blockquote-footer">Mclean Tyrece</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

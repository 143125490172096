import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import HtAuto from "../../Assets/HtAuto.png";
import Cards from "../Projects/notProject";
import Accueil from "../../Assets/realisations/htauto/accueil.png";
import PhpMvc from "../../Assets/realisations/htauto/phpmvc.jpg";
import Connexion from "../../Assets/realisations/htauto/connexionAdmin.png";
import Mosh from "../../Assets/realisations/mosh.png";
import Code from "../../Assets/realisations/camp.png";
import { FaPhp } from "react-icons/fa";
import { BsFiletypeCss } from "react-icons/bs";
import { MdHtml } from "react-icons/md";
import { TbBrandCSharp } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { SiMysql } from "react-icons/si";

function Veille() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" >
          <strong className="purple">Veille technologique</strong>
        </h1>
        <Row>
         
        
          <Col md={6} className="project-card">
            <Cards imgPath={Code} isBlog={true} />
          </Col>

          <Col md={6} className="project-card">
          <h3 className="project-heading">
          free code camp
        </h3>
        <p style={{ color: "white" }}>
         
          
        </p>
        </Col>
    
    </Row>
    <Row>
         
        
         <Col md={6} className="project-card">
           <Cards imgPath={Mosh} isBlog={true} />
         </Col>

         <Col md={6} className="project-card">
         <h3 className="project-heading">
         code with mosh
       </h3>
       <p style={{ color: "white" }}>
        
         
       </p>
       </Col>
   
   </Row>


       

      
      </Container>
    </Container>
  );
}

export default Veille;

import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaSymfony } from "react-icons/fa";
import { RiFlutterFill } from "react-icons/ri";
function TechstackD() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", color: "white" }}>
      

      <Col xs={4} md={2} className="tech-icons">
      <FaSymfony />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <RiFlutterFill />
      </Col>

      

      


      
    </Row>
  );
}

export default TechstackD;
